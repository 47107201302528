import React from 'react'
import Layout from '../../components/Layout'
import EventList from '../../components/EventList'
import Container from 'react-bootstrap/Container'

export default class EventIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Container>
          <h1>Events</h1>
          <section>
            <EventList />
          </section>
        </Container>
      </Layout>
    )
  }
}
